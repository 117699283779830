import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"

import useSiteMetadata from "../hooks/useSiteMetadata"

// define component

const SEO = ({ title, description, lang }) => {

  const { siteTitle, siteDescription, siteUrl, siteLanguage, titleTemplate } = useSiteMetadata()
  const { pathname } = useLocation()

  const seo = {
    title: title || siteTitle,
    titleTemplate: titleTemplate,
    description: description || siteDescription,
    language: lang || siteLanguage,
    url: `${siteUrl}${pathname}`
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      htmlAttributes={seo.lang}>

      <meta name="description" content={seo.description} />

      {seo.url &&
        <meta property="og:url" content={seo.url} />}

      {seo.title &&
        <meta property="og:title" content={seo.title} />}

      {seo.description &&
        <meta property="og:description" content={seo.description} />
      }

      {seo.title &&
        <meta name="twitter:title" content={seo.title} />
      }

      {seo.description &&
        <meta name="twitter:description" content={seo.description} />
      }

      <link rel="canonical" href={seo.url} />

    </Helmet >

  )
}


// type checking

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  lang: PropTypes.string
}

// default values

SEO.defaultProps = {
  title: ``,
  description: ``,
  lang: `de`,
}

export default SEO
