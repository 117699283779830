/** @jsx jsx */
import React from "react"
import { Box, jsx, Container, Heading, useColorMode, Styled } from "theme-ui"
import { Global } from "@emotion/core"
import { Link } from 'gatsby'
import ArrowLeft from "../icons/arrow-left"
// styles
import { resetStyles } from "../styles/index"

// components
import Header from "./Header"
import Footer from "./Footer"

const Layout = ({ title, location, children, className = ``, subPage }) => {

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  const [mode] = useColorMode()

  let darkStyles

  if (mode === `dark`) {
    darkStyles = {
      ".flag-dark": {
        WebkitFilter: "saturate(0.4)",
        // WebkitTransition: "-webkit-filter 300ms ease-in-out",
        filter: "saturate(0.1)",
      },
      ".country-card:hover .flag-dark": {
        WebkitFilter: "saturate(0.6)",
        filter: "saturate(0.6)",
      },
    }
  }

  // bcNav = bread crumb navigation
  let header, bcNav

  let dotSpace = 17
  let dotSize = 1

  if (subPage) {
    bcNav = (
      <Styled.a
        as={Link}
        to="/"
        sx={{
          display: `grid`,
          gridTemplateColumns: `24px 1fr`,
          gridColumnGap: 2,
          mt: 1,
          mb: [0],
          alignItems: `center`,
          color: `accent2`,
          textDecoration: `none`,
          svg: {
            height: `24px`,
            width: `24px`,
            transition: `transform 0.3s ease-in-out`,
          },
          "&:hover": { textDecoration: `none`, color: `indigo.9`, svg: { transform: `translateX(-4px)` } },
          ".primary": {
            color: `indigo.2`,
          },
          ".secondary": {
            color: `indigo.6`,
          },
        }}
      >
        <ArrowLeft />
        {` `}
        <div sx={{ fontSize: 1, fontWeight: `medium` }}>
          zurück
        </div>
      </Styled.a>
    )
  }

  if (isRootPath) {
    header = (
      <Heading as="h1" variant="styles.h1">
        {title}
      </Heading>
    )
  } else {
    header = (
      <Heading as="h1" variant="styles.h1">
        {title}
      </Heading>
    )
  }

  return (
    <>
      <Global styles={resetStyles} />
      <Global
        styles={t => ({
          body: {
            background: `linear-gradient(90deg, ${t.colors.background} ${dotSpace - dotSize
              }px, transparent 1%) center, linear-gradient(${t.colors.background
              } ${dotSpace - dotSize}px, transparent 1%) center, ${t.colors.dotColor
              }`,
            backgroundSize: `${dotSpace}px ${dotSpace}px`,
          },

          "::selection": {
            backgroundColor: t.colors.primary,
            color: t.colors.white,
          },

          pre: {
            fontFamily: `monospace`,
            fontSize: `1em`,
          },

          a: {
            color: t.colors.linkColor,
            transition: `all 0.25s var(--ease-in-out-quad)`,
            textDecoration: `none`,
            outline: `none`,
            "&:focus": {
              boxShadow: `none`,
            },
          },
          ...darkStyles,
        })}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          transition: t => t.colorModeTransition,
        }}
      >
        <Header />
        <Container
          sx={{
            position: `relative`,
            width: "100%",
            flex: "1 1 auto",
          }}
        >
          <Box as="main" className={className}>

            {bcNav}

            {header}

            {children}
          </Box>

          <Footer location={location} title={title} />
        </Container>
      </Box>
    </>
  )
}

export default Layout
