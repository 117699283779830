import { graphql, useStaticQuery } from "gatsby"

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle: title
          titleTemplate
          siteDescription: description 
          siteUrl
          siteLanguage: language
          footerLinks {
            name
            to
          }
        }
      }
    }
  `)
  return data.site.siteMetadata
}

export default useSiteMetadata
