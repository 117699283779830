/** @jsx jsx */
import { Container, Flex, jsx, useColorMode } from "theme-ui"
import { Link } from "gatsby"

import ColorModeToggle from "./ColorModeToggle"

const Header = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const toggleColorMode = e => {
    e.preventDefault()
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <header sx={{ marginBottom: 4 }}>
      <Container
        sx={{
          borderBottom: "4px solid",
          borderImage: theme =>
            `linear-gradient(to right, ${theme.colors.orange[3]}, ${theme.colors.orange[5]}) 1`,
        }}
      >
        <Flex sx={{ alignItems: `center`, justifyContent: `space-between` }}>
          <Link
            to="/"
            as="h2"
            sx={{
              fontFamily: `'Pacifico', cursive`,
              variant: "links.logo",
              fontSize: [3, 4, 5],
              py: 3,
            }}
          >
            Fussballwortschatz.de
          </Link>

          <ColorModeToggle isDark={isDark} toggle={toggleColorMode} />
        </Flex>
      </Container>
    </header>
  )
}

export default Header
