/** @jsx jsx */
import { Box, Flex, jsx } from "theme-ui"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import { Link } from "gatsby"
import useSiteMetadata from "../hooks/useSiteMetadata"
import replaceSlashes from "../utils/replaceSlashes"

const Footer = ({ title, location }) => {
  const { footerLinks, siteUrl } = useSiteMetadata()

  const shareUrl = replaceSlashes(`${siteUrl}/${location.pathname}`)

  return (
    <Box
      as="footer"
      sx={{
        display: `flex`,
        flexDirection: `column`,
        alignContent: `center`,
        mt: [3, 4],
        color: `text`,
        a: {
          variant: `links.secondary`,
        },
      }}
    >
      <Flex key="social-buttons" sx={{ justifyContent: `center` }}>
        <Box sx={{ p: 2 }}>
          <FacebookShareButton url={shareUrl} quote={title} className="">
            <FacebookIcon
              sx={{
                transition: t => t.floatTransition,
                "&:hover": {
                  marginTop: "-2px",
                },
              }}
              size={32}
              round
            />
          </FacebookShareButton>
        </Box>

        <Box sx={{ p: 2 }}>
          <TwitterShareButton url={shareUrl} title={title} className="">
            <TwitterIcon
              sx={{
                transition: t => t.floatTransition,
                "&:hover": {
                  marginTop: "-2px",
                },
              }}
              size={32}
              round
            />
          </TwitterShareButton>
        </Box>
      </Flex>

      <Box
        key="footer-nav"
        as="nav"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        {footerLinks.map(({ name, to }) => (
          <Link
            rel="nofollow"
            key={name}
            sx={{
              variant: "links.footer",
              ml: 3,
              py: 2,
            }}
            to={to}
          >
            {name}
          </Link>
        ))}
      </Box>
      <Box key="copy" sx={{ p: 2, textAlign: `center` }}>
        &copy; {new Date().getFullYear()}{" "}
        <Link to="/">fussballwortschatz.de</Link>
      </Box>
    </Box>
  )
}

export default Footer
